import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useLayoutEffect, useState } from "react";
import useDarkMode from "use-dark-mode";

export default function TopTenHeader() {
  const darkMode = useDarkMode(false, {
    classNameDark: "dark",
    classNameLight: "light",
  });

  return (
    <header className="bg-black">
      <nav className="container" aria-label="Top">
        <div className="flex items-center justify-center w-full py-6 ">
          <Link to="/">
            <span className="sr-only">Top 10 Balingen</span>
            <Logo isDark={true} />
          </Link>
        </div>
      </nav>
    </header>
  );
}

function Logo({ isDark }) {
  const [isSSR, setIsSSR] = useState(typeof window == "undefined");
  const { config } = useStaticQuery(graphql`
    {
      config: prismicConfig {
        data {
          logo_dark {
            fluid(maxWidth: 120) {
              src
            }
          }
          logo_white {
            fluid(maxWidth: 120) {
              src
            }
          }
        }
      }
    }
  `);
  useLayoutEffect(() => {
    setIsSSR(typeof window == "undefined");
  }, []);
  if (isSSR) {
    return null;
  } else {
    return isDark ? (
      <img src={config?.data?.logo_white?.fluid?.src} />
    ) : (
      <img src={config?.data?.logo_dark?.fluid?.src} />
    );
  }
}
