import { Link } from "gatsby";
import React from "react";

import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function CookieConsent() {
  if (typeof window == "undefined") {
    return null;
  }
  const consent = cookies.get("consent");
  return (
    !consent && (
      <div className="fixed z-[9000] left-0 w-full  bottom-8">
        <div className="container">
          <div className="flex items-center justify-between px-8 py-4 font-semibold text-white bg-black dark:bg-white dark:text-black ring-1 ring-white ">
            <span>
              Wir benutzen Cookies um die Nutzerfreundlichkeit der Webseite zu
              verbessen. Durch Deinen Besuch stimmst Du dem zu.
            </span>
            <span className="space-x-3 font-semibold">
              <button
                className="px-3 py-1 font-semibold text-black bg-white rounded dark:bg-black dark:text-white"
                onClick={(e) => {
                  cookies.set("consent", true, {
                    maxAge: 5 * 365 * 24 * 60 * 60,

                    path: "/",
                  });
                  setTimeout(() => {
                    window.open("/", "_self");
                  }, 300);
                }}
              >
                Verstanden
              </button>
              <a
                href="https://www.top10-balingen.de/cookie-einwilligung/"
                target="_blank"
                rel="nofollow noopener"
              >
                {" "}
                Weitere Informationen
              </a>
            </span>
          </div>
        </div>
      </div>
    )
  );
}
