import React from "react";
import Header from "./header";
import Footer from "./Footer";
import cn from "classnames";
import Seo from "./SEO";
import ErdbeermundLayout from "./ErdbeermundLayout";
import BerrysLayout from "./BerrysLayout";
import TopTenHeader from "./TopTenHeader";
import TopTenFooter from "./TopTenFooter";
import CookieConsent from "./Cookie";
export default function Layout({ children, transparent }) {
  return (
    <div
      className={cn("min-h-screen flex flex-col items-stretch", {
        "text-black bg-white dark:bg-black dark:text-white": !transparent,
        "text-black    dark:text-white": transparent,
      })}
    >
      <Seo />
      <LayoutMachine>{children}</LayoutMachine>
      <CookieConsent />
    </div>
  );
}

function LayoutMachine({ children }) {
  switch (process.env.GATSBY_PRISMIC_REPOSITORY) {
    case "erdbeermund-singen":
      return <ErdbeermundLayout>{children}</ErdbeermundLayout>;
    case "berrys-konstanz":
      return <BerrysLayout>{children}</BerrysLayout>;
    case "top10nightlife":
      return (
        <>
          {" "}
          <TopTenHeader />
          <main className="relative flex-grow bg-white">{children}</main>
          <TopTenFooter />
        </>
      );

    default:
      return (
        <>
          {" "}
          <Header />
          <main className="relative flex-grow">{children}</main>
          <Footer />
        </>
      );
  }
}
