import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import useDarkMode from "use-dark-mode";
import linkResolver from "./linkResolver";
import { RichText, Elements } from "prismic-reactjs";
import { css } from "@emotion/react";
import { Logo } from "./header";

export default function TopTenFooter() {
  const { config } = useStaticQuery(graphql`
    {
      config: prismicConfig {
        data {
          facebook_link
          instagram_link
          twitter_link
          columns {
            links1 {
              html
              raw
            }
          }
          legal_links {
            html
          }
        }
      }
    }
  `);

  return (
    <footer
      className="text-black bg-white border-t border-black/5 "
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center md:text-left md:flex md:items-center md:justify-between">
          <div
            className="flex items-center justify-center space-x-4 opacity-80"
            css={css`
              ul,
              li {
                display: inline-block;
              }
              li {
                margin-right: 1rem;
                margin-left: 0;
                @media (min-width: 768px) {
                  margin-right: 0;
                  margin-left: 1rem;
                }
              }
            `}
            dangerouslySetInnerHTML={{
              __html: config?.data?.legal_links?.html,
            }}
          ></div>
          <p className="mt-8 lg:mt-0 md:order-first">Copyright &copy; 2021</p>{" "}
        </div>
      </div>
    </footer>
  );
}
